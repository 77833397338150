/* Style the table */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  /* Style table header */
  th {
    background-color: #f2f2f2;
    text-align: left;
    padding: 10px;
  }
  
  /* Style table rows */
  tr {
    border-bottom: 1px solid #ddd;
  }
  
  /* Style table cells */
  td {
    padding: 10px;
  }
  
  /* Style the "Edit" and "Delete" buttons */
  button {
    padding: 5px 10px;
    margin-right: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Center the table */
  div.list {
    text-align: center;
  }
  
  /* Style the page title */
  h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  