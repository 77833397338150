.about-intro figure, .services-details figure {
  float: right;
  width: 90%;
  text-align: center;
  font-style: italic;
  font-size: smaller;
  text-indent: 0;
  border: none;
  margin: 0.5em;
  padding: 0.5em;
}
.about-intro img.scaled {
  width: 100%;
}

.about-intro figcaption,  .serviceslist figcaption {
  font-weight: bold;
  color: #fff;
  padding: 30px;
  background-color: #02093d;
  font-size: 1.5rem;
  line-height: 1.2rem;
  display: block;
}
.service-item-content figure{width: 400px;}

/* Add these CSS rules to your stylesheet */
.services-details {
  color:black;
}

.service-item {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc; /* Separating line */
}
/* Optional CSS for styling */
.service-item {
  margin-top: 20px;
}
.service-item-image figure {
  max-width: 90%;
  height: auto;
}
.service-item-image img {
  max-width: 100%;
  height: auto;
}

.service-item-text {
  font-size: 16px;
  line-height: 1.5;
  padding: 20px;
  /*display: flex;*/
  align-items: center;
}

.even .service-item-image {
  order: 1; /* Swap image and text positions for even-indexed items */
}

.even .service-item-text {
  order: 2; /* Swap image and text positions for even-indexed items */
}

.odd .service-item-image {
  order: 2; /* Swap image and text positions for odd-indexed items */
}

.odd .service-item-text {
  order: 1; /* Swap image and text positions for odd-indexed items */
}

/* Add any other styling you need for text, images, and the separating line */


.about-intro .intro {
  float: right;
  width: 90%;
  text-align: center;
  font-style: italic;
  font-size: 0.95rem;
  text-indent: 0;
  border: none;
  margin: 0 0.5em;
  padding: 0 0.5em;
}
.pre-container {
  white-space: pre-line;
  padding: 20px;
  border: 1px solid #ddd; /* Add a border for better visualization */
}
