/* Styling for the container of the PostForm */

  
  /* Styling for the form */
  div.form-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Styling for the form title */
  div.form-container h2 {
    font-size: 24px;
    margin-bottom: 0px;
  }
  
  /* Styling for form input fields and textarea */
  div.form-container  {
    margin-bottom: 15px;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="text"],input[type="email"],
  textarea {
    width: 100%;
    padding: 5px;
    margin:5px auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff; /* White background */

    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  input[type="text"]:focus,
  textarea:focus {
    border-color: #007bff; /* Highlight border color on focus */
    outline: none; /* Remove default focus outline */
    box-shadow: 0 2px 4px rgba(0, 123, 255, 0.25); /* Highlight box shadow on focus */
  }
  
  /* Styling for the submit button */
  button[type="submit"] {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  