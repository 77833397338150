/* CSS for image previews */
.image-preview {
  display: flex;
  align-items: center;
  gap: 20px;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.image-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ccc;
  margin-bottom: 8px;
}

.delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.delete-button i {
  font-size: 10px;
  color: red;
}
